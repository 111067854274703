import React, {useCallback, useEffect, useState} from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/pro-regular-svg-icons";
import {getCookie} from "cookies-next";
import * as Sentry from "@sentry/nextjs";

export default function DialogDuplicateVersion ({masterProductUUID,toggleDuplicateVersion,
                                                    versionProductUUID}) {


    const toggleDuplicateVersionInside = useCallback((): void => {
        if(showDuplicateDialog){
            changeDuplicateShow(false);
        }else{
            changeDuplicateShow(true);
        }


    }, []);
    useEffect(() => {

        toggleDuplicateVersion && toggleDuplicateVersion(toggleDuplicateVersionInside);
    }, [toggleDuplicateVersion, toggleDuplicateVersionInside]);


    const duplicateProduct = () => {

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"products/"+masterProductUUID+"/versions/"+versionProductUUID, {
            method: 'POST',

            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+  getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data){
                //   console.log("DATA DUPLICATE VERSION", data);
                if(data.message !== undefined){
                    Sentry.captureException("products/"+masterProductUUID+"/versions/"+versionProductUUID+": "+ data.message + " : " + getCookie("userMail"));
                }

                if(data.message === undefined){
                    //    setVersionIsLocked(data.data.locked.locked);
                    window.open("/editor/"+data.data.uuid, "_self")

                }


            });
    };


    const closeModalOutsideClick = (e) => {

        if( e.target.getAttribute("data-closemodal") === "true"){
            changeDuplicateShow(false);
        }
    }
    // };
    const [showDuplicateDialog, setShowDuplicateDialog] = useState(false);
    const [showDuplicateDialogTransition, setShowDuplicateDialogTransition] = useState(false);
    const changeDuplicateShow = (variable) => {
        console.log("CHANGE SHOW", variable);
        if (variable) {
            setShowDuplicateDialog(true);
            setTimeout(function() {
                setShowDuplicateDialogTransition(variable);
            }, 2);
        } else {
            console.log("CHANGE nasravuju false", variable);
            setShowDuplicateDialogTransition(false);
            setTimeout(function() {
                setShowDuplicateDialog(false);
            }, 200);
        }
    };


    return (
        <>
            <ModalsWrapper
                className={`  ${showDuplicateDialogTransition ? 'showTransition' : 'hideTransition'} ${!showDuplicateDialog ? 'hiddeComplete' : ''}`}
              data-closemodal={true}
                onClick={(e)=> closeModalOutsideClick(e)}>


                <ModalWindow id={'duplicateDialogVersion'} style={{ minHeight: 'unset', height: '170px' }}
                             className={'center modal_dialog_small'}>
                    <div className={'modal-header'}>
                        <h4>New version</h4>

                    </div>


                    <div className={'modal-content shareProduct'} style={{ display: 'block' }}>
                        <span>Do you want to duplicate this version ?</span>

                        <div className={'modal-buttons'} style={{    width: "90%"}}>

                            <GlobalButtonFinal
                                className={'borderBlue btn-circle'}
                                onClick={() => changeDuplicateShow(false)}>
                                Cancel
                            </GlobalButtonFinal>

                            <GlobalButtonFinal  className={'backgroundBlue btn-circle '}
                                                data-uuid={'productID'}
                                                onClick={ () => duplicateProduct(masterProductUUID, versionProductUUID) }>
                                <FontAwesomeIcon icon={faPlus} /> Duplicate version
                            </GlobalButtonFinal>
                        </div>

                    </div>
                </ModalWindow>


            </ModalsWrapper>






        </>
    )
}