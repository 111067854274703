import React, { useCallback, useEffect, useState } from 'react';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../GlobalStyles';
import { getCookie } from 'cookies-next';
import { faCheck, faBuilding } from '@fortawesome/pro-regular-svg-icons';
import * as Sentry from '@sentry/nextjs';
import ObaleroFileUploader from '../ObaleroFileUploader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function DialogCreateNewTeam({ toggleCreateNewTeam, loadTeamsForSelect, switchTeam }) {
    const toggleCreateNewTeamInside = useCallback((): void => {
        if (modalNewTeamActive) {
            changeModalNewTeamActive(false);
        } else {
            changeModalNewTeamActive(true);
        }
    }, []);
    useEffect(() => {
        toggleCreateNewTeam && toggleCreateNewTeam(toggleCreateNewTeamInside);
    }, [toggleCreateNewTeam, toggleCreateNewTeamInside]);

    const setInputTeamLogoFunction = file => {
        setInputTeamLogo(file);
    };

    const [inputTeamLogo, setInputTeamLogo] = useState();

    const [newTeamName, setNewTeamName] = useState('');

    const handleNewTeamName = function (e: React.FormEvent<HTMLInputElement>) {
        setNewTeamName(e.currentTarget.value);
    };

    const AddNewTeam = () => {
        if (file !== null) {
            uploadNewTexture();
        } else {
            gotResponseUploadTextureAPI2('success', null, 'teamLogo');
        }
    };

    const uploadNewTexture = () => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'uploads/prepare-form', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                Authorization: 'Bearer ' + getCookie('userT'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function (data) {
                if (data.message !== undefined) {
                    Sentry.captureException('uploadNewTexture DialogCreateNewTeam uploads/prepare-form' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

                let formData = new FormData();

                Object.keys(data.formInputs).forEach(key => {
                    formData.append(key, data.formInputs[key]);
                });

                formData.append('file', file);

                fetch(data.formAttributes.action, {
                    method: data.formAttributes.method,
                    // headers: {
                    //     'Content-type': data.formAttributes.enctype
                    // },
                    body: formData,
                })
                    .then(function (dataUpload) {
                        gotResponseUploadTextureAPI2('success', '/' + data.formInputs.key, 'teamLogo');
                    })
                    .catch(function (e) {
                        Sentry.captureException(data.formAttributes.action + ': ' + data.message + ' : ' + getCookie('userMail'));
                    });

                //uploadEditorTexture(inputTeamLogo, data.formInputs.key, gotResponseUploadTextureAPI2, "teamLogo");
            });
    };
    const gotResponseUploadTextureAPI2 = (response, key, type) => {
        if (response === 'success') {
            //updateOptionAPI(type, key);
            fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'teams', {
                method: 'POST',
                body: JSON.stringify({
                    name: newTeamName,
                    logo_path: key,
                }),
                headers: {
                    'Content-type': 'application/json',
                    Authorization: 'Bearer ' + getCookie('userT'), // notice the Bearer before your token
                },
            })
                .then(response => response.json())
                .then(function (data) {
                    if (data.message !== undefined) {
                        Sentry.captureException('gotResponseUploadTextureAPI2 DialogCreateNewTeam teams' + ': ' + data.message + ' : ' + getCookie('userMail'));
                    }

                    console.log('FULL RESPONSE NEW TEAM', data);
                    if (data.message === undefined) {
                        changeModalNewTeamActive(false);
                        if (switchTeam) {
                            switchTeam(data.data.uuid);
                        } else {
                            if (loadTeamsForSelect) {
                                loadTeamsForSelect();
                            }
                        }
                    }
                });
        }
    };
    const [modalNewTeamActive, changeModalNewTeamActive] = useState(false);

    const closeModal = () => {
        setFile(null);
        changeModalNewTeamActive(false);
    };
    const closeModalOutsideClick = e => {
        console.log(e.target, e.target.getAttribute('data-closemodal'));

        if (e.target.getAttribute('data-closemodal') === 'true') {
            changeModalNewTeamActive(false);
        }
    };
    const [file, setFile] = useState(null);

    return (
        <>
            <ModalsWrapper className={modalNewTeamActive ? '' : 'hideModal'} data-closemodal={true} onClick={e => closeModalOutsideClick(e)}
            style={{zIndex: "9999999999999999999"}}>
                <ModalWindow id={'newTeamModal'} className={` modal_dialog_small ${modalNewTeamActive ? '' : 'hideModal'} `}>
                    <div className={'modal-header'}>
                        <h4 style={{ textAlign: 'center', fontSize: '22px !important', paddingBottom: '30px' }}>Create new team</h4>
                    </div>
                    <br />

                    <div className={'modal-content  editGroups'} style={{ display: 'block' }}>
                        <input type={'text'} placeholder={'Name'} value={newTeamName} onChange={handleNewTeamName} style={{ width: '100%' }} />

                        <div style={{ width: '25%', display: 'inline-block', marginTop: '25px' }}>
                            <h5>Upload icon</h5>
                            {file ? (
                                <img style={{ border: '1px solid lightgray', maxWidth: '100%' }} src={URL.createObjectURL(file)} />
                            ) : (
                                <div className={'iconWithoutLogo'}>
                                    <FontAwesomeIcon icon={faBuilding} />
                                </div>
                            )}
                        </div>

                        <div style={{ width: '71.6%', display: 'inline-block' }}>
                            <ObaleroFileUploader setFile={setFile} file={file} isSmall={true} />
                        </div>
                        <br />
                    </div>
                    <div className={'modal-buttons'}>
                        <GlobalButtonFinal className={'borderBlue btn-circle'} onClick={() => closeModal()}>
                            Cancel
                        </GlobalButtonFinal>
                        <GlobalButtonFinal className={'backgroundBlue btn-circle'} onClick={AddNewTeam}>
                            <FontAwesomeIcon icon={faCheck} style={{ marginRight: '8px' }} />
                            Save
                        </GlobalButtonFinal>
                    </div>
                </ModalWindow>
            </ModalsWrapper>
        </>
    );
}
