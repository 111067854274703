import Link from 'next/link';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GlobalButtonFinal, ModalsWrapper, ModalWindow } from '../GlobalStyles';
import { signOut, useSession } from 'next-auth/client';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import ShareDialog from './ShareDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/nextjs';

import {
    faBars,
    faBell, faCaretRight,
    faCircleQuestion,
    faComments,
    faLock,
    faPlus,
    faUnlock,
    faUser,
    faUsers,
    faRightFromBracket,
    faCoins,
    faUp,
    faArrowRightArrowLeft
} from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';
import { useRouter } from 'next/router';
import DialogCreateNewTeam from '../dialogs/DialogCreateNewTeam';
import DialogLockVersion from '../dialogs/DialogLockVersion';
import DialogDuplicateVersion from '../dialogs/DialogDuplicateVersion';
import DialogSwitchTeams from '../dialogs/DialogSwitchTeams';


export const MainMenuHeaderNew = styled.div`

    @media screen and (max-width: 425px) {
        .menuControlButtons {
            display: none;
        }

    }

    @media screen and (max-width: 991px) {
        .breadcrumbs {
            display: none;
        }

    }


    .upgradeDiv {
        // background: #f4f4f4;
        background: white;
        position: fixed;
        bottom: 25px;
        margin-left: 25px;
        margin-right: 30px;
        border-radius: 22px;
        padding: 15px;
        z-index: 1000;
        border: thin solid rgba(0, 0, 0, 0.3);

        p {
            font-size: 11pt;
        }

        h4 {
            margin-top: 5px;
            margin-bottom: 5px !important;
        }

        .bulbIcon {
            position: absolute;
            top: 20px;
            height: 1.2rem;
            right: 15px;
            opacity: 0.5;
        }

        @media (max-width: 400px) {
            display: none;
        }

    }
    

    li {
        &:hover {
            cursor: pointer;
        }
    }


    /* ==============================
       Top bar
       ============================== */

    .top-bar {
        min-height: 40px;
        background: #fbfbfb;
        border-bottom: 1px solid rgba(0, 0, 0, .05);
        font-size: 11px;
        letter-spacing: 1px;
    }

    /*
     * Top links
     */

    .top-links {
        list-style: none;
        margin: 0;
        padding: 0;
        text-transform: uppercase;
    }

    .top-links li {
        float: left;
        border-left: 1px solid rgba(0, 0, 0, .05);
    }

    .top-links li:last-child {
        border-right: 1px solid rgba(0, 0, 0, .05);
    }

    .top-links li .fa {
        font-size: 14px;
        position: relative;
        top: 1px;
    }

    .top-links li .tooltip {
        text-transform: none;
        letter-spacing: normal;
        z-index: 2003;

    }

    .top-links li a {
        display: block;
        min-width: 40px;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        text-decoration: none;
        text-align: center;
        color: #999;

        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;

        -webkit-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.1s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }

    .top-links li a:hover {
        color: #555;
        background-color: rgba(0, 0, 0, .02);
    }

    /*
     * Top bar dark
     */

    .top-bar.dark {
        background-color: #333;
        border-bottom: none;
    }

    .top-bar.dark .top-links li,
    .top-bar.dark .top-links li:last-child {
        border-color: rgba(255, 255, 255, .07);
    }

    .top-bar.dark .top-links li a {
        color: #aaa;
    }

    .top-bar.dark .top-links li a:hover {
        color: #ccc;
        background-color: rgba(255, 255, 255, .05);
    }


    /* ==============================
       Classic menu bar
       ============================== */


    /* Logo (in navigation panel)*/

    .nav-logo-wrap {
        float: left;
        margin-right: 20px;

        h4 {
            font-size: 14px !important;
            color: #fff;
            opacity: 60%;
            //letter-spacing: 2px;
            font-weight: 300;
            //text-transform:uppercase;
        }
    }
.nav-logo-wrap:hover{
    cursor:pointer;
}
.nav-logo-wrap .logo {
        display: flex;
        align-items: center;
        //max-width: 118px;
        height: 75px;
        width: 155px;
        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }

    .nav-logo-wrap .logo img {
        width: auto;
        max-height: 45%;
        margin-left: 15px;
        height: 24px;

        @media (max-width: 400px) {
            height: 20px;
        }

    }

    .nav-logo-wrap .logo:before,
    .nav-logo-wrap .logo:after {
        display: none;
    }

    .logo,
    a.logo:hover {
        font-family: 'Nunito Sans', sans-serif !important;
        font-size: 18px;
        font-weight: 400 !important;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        text-decoration: none;
        color: rgba(0, 0, 0, .9);
    }

    /*
     * Desktop nav
     */

    .main-nav {
        width: 100%;
        height: 75px !important;
        position: relative;
        top: 0;
        left: 0;
        text-align: left;
        background: rgba(255, 255, 255, .97);

        -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
        -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, .05);
        box-shadow: 0 1px 0 rgba(0, 0, 0, .05);

        z-index: 999999999;

        -webkit-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: height 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }


    .small-height {
        height: 54px !important;
    }

    .small-height .inner-nav ul li a {
        height: 54px !important;
        line-height: 54px !important;
    }

    .inner-nav {
        display: inline-block;
        position: relative;
        float: right;
        //padding-right: 5px;

        .buttonMenuMain {

            //minWidth: "50px",
            //marginLeft: "15px",
            //marginRight: "-30px"

            &.mainMenuButton {
                transition: al 0.3s ease;

                &:hover,
                &.active {
                    opacity: 100%;

                    svg, span {
                        opacity: 100%;
                    }
                }
            }
        }

        .faMenuBars {
            filter: invert(1);
        }

        .notificationBell {


            padding-right: 10px;
            max-height: 54px;
            height: 54px;

            svg {
                top: 8px;
                left: 12px;
            }

            .notificationBellIcon {
                filter: invert(1);
                transition: all 0.3s ease;
                opacity: 60%;

            }

            &:hover,
            &.active {
                .notificationBellIcon {
                    opacity: 100%;
                }

            }
        }

        .alertNumber {
            background: red;
            border-radius: 40%;
            -webkit-filter: invert(0);
            filter: invert(0);
            font-size: 11px !important;
            opacity: 100% !important;
            font-weight: 600;
            color: white;
            top: 1px;
            left: 15px;
            width: 34px;
            display: inline-block;
            height: 22px;
            position: relative;
            line-height: 2.2;
        }

        .menuControlButtons {
            margin-top: 8px;
            float: left;
        }

        .menuButtons {
            padding: 5px;
            padding-left: 15px;
            padding-right: 15px;
            margin-right: 5px;
            transition: all 0.3s ease;

            span {

            }

            color: white;
            opacity: 100%;

            .iconBtn {
                font-size: 14px;
                //filter:invert(1);
                color: white;
                margin-right: 8px;
                opacity: 60%;
                //style={{height: "1.6em", filter:"invert(1)"}}
            }

            &:hover {
                color: white !important;
                opacity: 100%;
                background: transparent !important;

                span {
                    opacity: 100%;
                }

                .iconBtn {
                    opacity: 100%;
                    //filter:invert(1);
                }
            }

            //&.renderButton{
            //  &:hover{
            //    background:#3d55d7 !important;
            //    color:white !important;
            //    span{
            //      opacity:100%;
            //      color:white !important;
            //    }
            //    .iconBtn{
            //      opacity:100%;
            //      filter:invert(0);
            //    }
            //  }
            //}
        }

        .mainMenuUl {
            max-height: 54px;
            padding: 0;
            width: 98px;

            &.withNotification {
                width: 150px;
            }

            //li{
            //  max-height: 54px;
            //}

        }
    }

    .inner-nav ul {
        float: right;
        margin: auto;
        font-size: 11px;
        font-weight: 400;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-align: center;
        line-height: 1.3;
        list-style-type: none;
    }

    .inner-nav ul li {
        float: left;
        //margin-left: 30px;
        position: relative;
        line-height: 3.9;

        svg,
        img {
            font-size: 14px;
            top: 0px;
            position: relative;
        }

        img {
            border: 1px solid #565656;
        }

        //svg{
        //  //filter:invert(1);
        //  
        //  top: 2px;
        //}

        > div {
            line-height: 1.3;
        }

        img {
            border-radius: 50%;
            top: -2px;
        }
    }

    .inner-nav ul li a {
        color: #777;
        letter-spacing: 2px;
        display: inline-block;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-decoration: none;

        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }

    .inner-nav ul > li:first-child > a {

    }

    //.inner-nav ul li a:hover,
    //.inner-nav ul li a.active{
    //  color: #000;
    //  text-decoration: none;
    //  
    //  svg{
    //    color: #000;
    //  }
    //}


    /* Menu sub */

    .mn-sub {
        //display: none;
      width: 260px;
        position: absolute;
        //top: 100%;
        //left: 0;
        padding: 0;
        //background: rgba(30,30,30, .97);
        //transform: translateY(-500px);
        opacity: 0;
        border-bottom-left-radius: 22px;
        border-bottom-right-radius:0;
      right: -24px !important;
       
        transition: all 0.3s ease;
        background: black;
      
        top: 53px;
        visibility: hidden;
          padding-left: 10px;
          padding-bottom:10px;
          
        height:fit-content;
        overflow-y:scroll;
      .dividerText{
        font-size: 18px !important;
        position: relative;
        color: rgba(255, 255, 255, 0.6);

        text-align: center;
        

        font-style: normal;
        font-weight: 300;
        line-height: normal;
        width: 90%;
        position: relative;
        margin:auto auto;
        display: block;

        &:before {
          content: "";
          display: block;
        
          height: 1px;
          background: rgba(255, 255, 255, 0.6);
          left: 0;
          top: 50%;
          width: 70px;
          position: absolute;
        }


        &:after {
          content: "";
          display: block;
          width: 70px;
          height: 1px;
          background: rgba(255, 255, 255, 0.6);
          right: 0;
          top: 50%;
          position: absolute;
        }
        
        &.team{
          &:before {
            width: 60px;
          }


          &:after {
            width: 60px;
          }
        }
        
      }

        .teamSwitcher {
            img {
                margin-right: 10px;
                //max-width: 15px;
                //
                //width: 15px;
                //height: 15px;
            }

            svg {
                //width: 15px;
                margin-right: 10px;
            }

            &.active {
                background: rgba(255, 255, 255, .09);
                color: #f5f5f5 !important;
            }
          span{
            color: var(--black-40, #999);
            font-family: "Nunito Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        &.show {
            //   display:block;
            visibility: visible;
            opacity: 1 !important;
            //transform: translateY(0);
        }

        .userInfo,
        .teamInfo{
            text-align: left !important;
            padding-bottom: 15px;

            h5 {
         
     
      
                text-align: left !important;
                margin-top: 15px;
              
              color: var(--black-15, #D9D9D9);
              font-family: "Nunito Sans";
              font-size: 16px !important;
              font-style: normal;
              font-weight: 400 !important;
              line-height: normal;
          //    margin-bottom:0 !important;
              
            }

            span {

              color: var(--black-40, #999) !important;
              font-family: "Nunito Sans";
              font-size: 16px  !important;
              font-style: normal !important;
              font-weight: 300;
              line-height: normal;
            }

            .plan {
              color: var(--black-100, #000) !important;
              font-family: "Nunito Sans" !important;
              font-size: 10px !important;
              font-style: normal;
              padding: 1px 5px;
              font-weight: 700;
              line-height: normal;
              letter-spacing: normal;
              border-radius: 3px;
              background: var(--black-40, #999) !important;
            }
          .credits{
            color: var(--black-15, #D9D9D9) !important;
            font-family: "Nunito Sans";
            font-size: 10px !important;
            font-style: normal !important;
            
            font-weight: 700;
            line-height: normal;
            margin-left:10px;
          }

            hr {
                width: 85%;
                background: lightgray;
                height: 2px;
                opacity: 1;
                margin: auto;
            }
          
          img{
            width:48px;
            height:48px;
          }
        }
      
      &.sub-teams{

        &:after,
        &:before {
          left: 100%;
          top: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }
        &:after {
          //border-color: black;
          border-left-color: black;
          border-width: 10px;
          margin-top: -10px;
        }
        
        img,
        svg{
          width:48px;
          height:48px;
          max-width:unset;
        }
        
        .createTeam{
          color: var(--black-40, #999) !important;
          /* Top Menu/Item */
          font-family: "Nunito Sans";
          font-size: 14px !important;
          font-style: normal !important;
          font-weight: 400 !important;
          line-height: normal !important;
        }
        
      }

  
        
        &.menuInside{
            height:510px;
            img,svg{
                top:2px;
            }
        }
        
        &.teamSelect{
            height:240px;
            img,svg{
                top:2px;
            }
        }
    }

    .mn-sub li {
        display: block;
        width: 100%;
        margin: 0 !important;

        hr {
            width: 85%;
            background: lightgray;
            height: 2px;
            opacity: 1;
            margin: auto;
        }


    }

    .inner-nav ul li .mn-sub li a {
        display: block;
        width: 100%;
        height: auto !important;
        line-height: 1.3 !important;
        position: relative;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin: 0;
        padding: 10px 15px;
        text-align: left;
        text-transform: none;
        border-left: none;
        border-right: none;
        letter-spacing: 0;
      
      color: var(--black-40, #999) !important;
      /* Top Menu/Item */
      font-family: "Nunito Sans";
      font-size: 14px  !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      
        outline-offset: -2px !important;
        cursor: pointer;
    }

    .inner-nav ul li .mn-sub li a span {

        font-size: 13px;

        color: #b0b0b0 !important;


    }


    .mobile-on .mn-has-multi > li:last-child > a {
        border-bottom: 1px solid rgba(255, 255, 255, .065) !important;
    }

    .mn-sub li a:hover,
    .inner-nav ul li .mn-sub li a:hover,
    .mn-sub li a.active {
        background: rgba(255, 255, 255, .09);
        color: #f5f5f5 !important;
    }

    .mn-sub li ul {
        left: 100%;
        right: auto;
        top: 0;
        border-left: 1px solid rgba(255, 255, 255, .07);
    }

    .inner-nav > ul > li:last-child .mn-sub,
    .inner-nav li .mn-sub.to-left {
        left: auto;
        right: 0;
    }

    .inner-nav > ul > li:last-child .mn-sub li ul,
    .inner-nav > li:last-child .mn-sub li ul,
    .inner-nav li .mn-sub li ul.to-left {
        left: auto;
        right: 100%;
        top: 0;
        border-left: none;
        border-right: 1px solid rgba(255, 255, 255, .07);
    }

    .mobile-on .inner-nav li:last-child .mn-sub li ul {
        left: auto;
        right: auto;
        top: 0;
    }

    .mn-wrap {
        padding: 8px;
    }

    /* Menu sub multi */

    .mn-has-multi {
        width: auto !important;
        padding: 15px;
        text-align: left;
        white-space: nowrap;
    }

    .mn-sub-multi {
        width: 190px !important;
        overflow: hidden;
        white-space: normal;
        float: none !important;
        display: inline-block !important;
        vertical-align: top;
    }

    .mn-sub-multi > ul {
        width: auto;
        margin: 0;
        padding: 0;
        float: none;
    }

    .mn-group-title {
        font-weight: 700;
        text-transform: uppercase;
        color: #fff !important;
    }

    .mn-group-title:hover {
        background: none !important;
    }

    .mobile-on .mn-sub-multi > ul {
        left: auto;
        right: auto;
        border: none;
    }

    .mobile-on .mn-sub-multi {
        display: block;
        width: 100% !important;
    }


    .mobile-on .mn-sub-multi > ul > li:last-child > a {
        border: none !important;
    }

    .mobile-on .mn-sub:not(.mn-has-multi) > li:last-child > a {
        border: none !important;
    }

    .mobile-on .mn-sub-multi:last-child > ul {
        border-bottom: none !important;
    }

    /*
     * Mobile nav
     */

    .mobile-on .desktop-nav {
        display: none;
        width: 100%;
        position: absolute;
        top: 100%;
        left: 0;
        padding: 0 15px;

        z-index: 100;
    }

    .mobile-on .full-wrapper .desktop-nav {
        padding: 0;
    }


    .mobile-on .desktop-nav ul {
        width: 100%;
        float: none;
        background: rgba(18, 18, 18, .97);
        position: relative;
        overflow-x: hidden;
    }

    .mobile-on .desktop-nav > ul {
        overflow-y: auto;
    }

    .mobile-on .desktop-nav ul li {
        display: block;
        float: none !important;
        overflow: hidden;
    }

    .mobile-on .desktop-nav ul li ul {
        padding: 0;
        border-bottom: 1px solid rgba(255, 255, 255, .1);
    }

    .mobile-on .desktop-nav ul li a {
        display: block;
        width: 100%;
        height: 38px !important;
        line-height: 38px !important;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0 10px;
        text-align: left;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(255, 255, 255, .065);
        color: #999;
        letter-spacing: 1px;
        cursor: pointer;
    }

    .mobile-on .desktop-nav ul li a:hover,
    .mobile-on .desktop-nav ul li a.active {
        background: rgba(255, 255, 255, .03);
        color: #ddd;
    }

    .mobile-on .desktop-nav ul li a:focus {
        outline-offset: -2px !important;
    }


    /* Menu sub */

    .mobile-on .mn-has-sub > .fa {
        display: block;
        width: 18px;
        height: 18px;
        font-size: 14px;
        line-height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -8px;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, .1);

        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
    }

    .mobile-on .js-opened > .mn-has-sub > .fa {
        border-color: rgba(255, 255, 255, .4);
        line-height: 14px;
    }

    .mobile-on .inner-nav li {
        margin: 0 !important;
    }

    .mobile-on .mn-sub {
        display: none;
        opacity: 0;
        position: relative;
        left: 0 !important;
        right: 0;
        top: 0;
        bottom: 0;
        border-left: none !important;
        border-right: none !important;
        border-top: none;
        z-index: 10;

        &.show {
            display: block;
        }
    }

    .mobile-on .mn-sub li {
        padding-left: 10px;
    }


    .inner-nav li.js-opened .mn-sub {
        opacity: 1;
    }


    .mobile-nav {
        display: none;
        float: right;
        vertical-align: middle;
        font-size: 11px;
        font-weight: 400;
        text-transform: uppercase;
        border-left: 1px solid rgba(0, 0, 0, .05);
        border-right: 1px solid rgba(0, 0, 0, .05);
        cursor: pointer;

        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;

        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);

        -webkit-transition-property: background, color, height, line-height;
        -moz-transition-property: background, color, height, line-height;
        -o-transition-property: background, color, height, line-height;
        transition-property: background, color, height, line-height;
    }

    .mobile-nav:hover,
    .mobile-nav.active {
        background: #fff;
        color: #777;
    }

    .mobile-nav:active {
        -webkit-box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
        -moz-box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
        box-shadow: 0 0 35px rgba(0, 0, 0, .05) inset;
    }

    .no-js .mobile-nav {
        width: 54px;
        height: 54px;
    }

    .mobile-on .mobile-nav {
        display: table;
    }

    .mobile-nav .fa {
        height: 100%;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-size: 21px;
    }

    /* Menu sub multi */

    .mobile-on .mn-has-multi {
        width: auto;
        padding: 0;
        text-align: left;
        white-space: normal;

    }

    .mobile-on .mn-sub {
        width: 100%;
        float: none;
        position: relative;
        overflow-x: hidden;
    }

    /*
     * Dark panel style
     */

    .main-nav {
        span {
            font-weight: 400;
            text-transform: none;
            font-size: 14px !important;
            color: white;
            opacity: 60%;
        }

        .subVersions {
            left: -43px;
            top: 53px;
            color: white;
            padding: 22px;
            opacity: 0;
            //z-index: -1;
            border-bottom-left-radius: 22px;
            background: black;
            border-bottom-right-radius: 22px;
            padding-top: 10px;

            li {
                margin-top: 15px !important;
                line-height: 1 !important;
                font-size: 14px;
                opacity: 60%;
                transition: all 0.3s ease;

                &:hover {
                    opacity: 100%;
                }
            }

            //&.show{
            //  opacity:1;
            //}
        }

        .notificationMenu {
            width: 300px;
            left: -149px;
            top: 54px;
            padding-top: 0;

            .notification {
                border-bottom: 1px solid lightgray;
                padding-bottom: 10px;
                max-height: unset !important;
                padding-top: 15px;
            }

            .notificationIcon {
                width: 20%;
                display: inline-block;
                float: left;
                font-size: 14px;

                svg {
                    width: 100%;
                    left: 0;
                    top: 0;
                }
            }

            .notificationDesc {
                width: 80%;
                display: inline-block;
                float: left;
                text-align: left;

                h6 {
                    font-size: 14px !important;
                    text-align: left;
                    margin-bottom: 3px !important;
                    text-transform: none !important;
                    font-weight: 200;
                }

                span {
                    font-size: 11px !important;
                    opacity: 60%;
                    font-weight: 200;
                }
            }
        }

        .breadcrumbs {
            float: left;
            line-height: 3.4;
            margin-left: -4px;

            ul {
                list-style-type: none;

                li {
                    float: left;
                    display: inline-block;
                    position: relative;
                    z-index: 8;

                    &::marker {
                        display: none;
                    }

                    ::marker {
                        display: none;
                    }

                    &:hover,
                    &.active{
                    span{
                        opacity:100%;
                    }
                            opacity: 100%;
                        

                    }
                }
            }

            .breadcrumbArrow {
                font-size: 9pt;
                opacity: 60%;
                margin-left: 10px;
                margin-right: 10px;
                -webkit-filter: invert(1);
                filter: invert(1);
                position: relative;
                top: 7px;
            }

            .breadcrumbsButtons {
                //margin-left:15px;
                //width: 80px;
                top: 12px;
                display: inline-block;
                border-radius: 50%;
                position: relative;
                //top:6px;
                height: 30px;
                width: 30px;
                transition: all 0.3s ease;

                svg:not(:first-child) {
                    margin-left: 5px;
                }

                .brButton {
                    transition: all 0.3s ease;

                    opacity: 60%;
                    // color:black;
                    padding: 0;
                    position: relative;
                    font-size: 14px;
                    margin: 0;
                    top: -12px;
                    left: 8px;

                    &:active,
                    &:focus,
                    &:hover {
                        outline: none;
                    }
                }

                &:hover {
                    opacity: 100%;
                    //background:darkgray;

                    .brButton {
                        opacity: 100%;
                    }
                }
            }
        }


        .menuIcon {
            font-size: 14pt;
            //margin-left:10px;
            filter: invert(1);
        }
    }

    .main-nav.dark {
        background-color: black;

        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .main-nav.dark .inner-nav ul > li > a {
        color: rgba(255, 255, 255, .7);
    }

    .main-nav.dark .inner-nav ul > li > a:hover,
    .main-nav.dark .inner-nav ul > li > a.active {
        color: #fff;
    }

    .main-nav.dark .mobile-nav {
        background-color: transparent;
        border-color: transparent;
        color: rgba(255, 255, 255, .9);
    }

    .main-nav.dark .mobile-nav:hover,
    .main-nav.dark .mobile-nav.active {
        color: #fff;
    }

    .main-nav.mobile-on.dark .inner-nav {
        border-top: 1px solid rgba(100, 100, 100, .4);
    }

    .main-nav.dark .logo,
    .main-nav.dark a.logo:hover {
        font-size: 18px;
        font-weight: 800;
        text-transform: uppercase;
        text-decoration: none;
        color: rgba(255, 255, 255, .9);
    }

    .main-nav.dark .mn-sub {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .main-nav.dark .mn-soc-link {
        border: 1px solid rgba(255, 255, 255, .2);
    }

    .main-nav.dark .mn-soc-link:hover {
        border: 1px solid rgba(255, 255, 255, .3);
    }

    /*
     * Transparent panel style
     */

    .main-nav.transparent {
        background: transparent !important;
    }

    .main-nav.js-transparent {
        -webkit-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -moz-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -o-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        -ms-transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
        transition: all 0.27s cubic-bezier(0.000, 0.000, 0.580, 1.000);
    }


    /* 
     * Centered menu 
     */

    .mn-centered {
        text-align: center;
    }

    .mn-centered .inner-nav {
        float: none;
    }

    .mn-centered .inner-nav > ul {
        float: none;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
    }

    .mn-centered .inner-nav > ul > li:first-child {
        margin-left: 0;
    }

    .mn-centered .mobile-nav {
        float: none;
        margin-left: auto;
        margin-right: auto;
    }



`;

export default function MainMenu({
                                     typePlugin,
                                     typeEditor,
                                     sideMenuPage,
                                     breadcrumbsAPI,
                                     showUserMenu,
                                     changeShowUserMenu,
                                     typeShareView,
                                     hitTriggerShowRender,
                                     masterProductUUID,
                                     teamCredits,
                                     setTeamCredits,
                                     versionProductUUID,
                                     versionIsLocked,
                                     setVersionIsLocked,
                                 }) {
    const router = useRouter();
    const { slug } = router.query;


    const [teamCreditsInner, setTeamCreditsInner] = useState(1);


    const [slugState, setSlugState] = useState(null);

    const [showSwitchTeams, changeShowSwitchTeams] = useState(false);

    const [showNotifications, changeShowNotifications] = useState(false);
    const [session, loading] = useSession();

    const [showVersions, changeShowVersions] = useState(false);
    const [dropdownAllowed, changeDropdownAllowed] = useState(true);

    const [userDeveloper, setUserDeveloper] = useState(false);


    const changeShowDropdownFunction = (value, type) => {

        if (dropdownAllowed && value) {
            if (type === 'versions') {
                changeShowVersions(value);
            }
            if (type === 'packageType') {
                changeShowPackageType(value);
            }
            if (type === 'brand') {
                changeShowBrand(value);
            }
            if (type === 'edition') {
                changeShowEdition(value);
            }
            if (type === 'client') {
                changeShowClient(value);
            }
            changeDropdownAllowed(false);

            setTimeout(function() {
                changeDropdownAllowed(true);
            }, 300);
        } else {
            changeShowVersions(false);
            changeShowPackageType(false);
            changeShowBrand(false);
            changeShowEdition(false);
            changeShowClient(false);
            changeShowBrand(false);
            setTimeout(function() {
                changeDropdownAllowed(true);
            }, 300);

        }


    };


    const [showPackageType, changeShowPackageType] = useState(false);

    const [showClient, changeShowClient] = useState(false);
    const [showBrand, changeShowBrand] = useState(false);
    const [showEdition, changeShowEdition] = useState(false);


    const [userAvatar, setUserAvatar] = useState('default');
    const [teamAvatar, setTeamAvatar] = useState('default');
    const [userEmail, setUserEmail] = useState('default email');
    const [userName, setUserName] = useState('default');
    const [userUUID, setUserUUID] = useState('default');
    const [userTeams, setUserTeams] = useState([]);


    useEffect(() => {
        setSlugState(slug);

    }, []);


    const [currentClient, setCurrentClient] = useState(null);
    const [allClients, setAllClients] = useState([]);

    const [currentBrand, setCurrentBrand] = useState(null);
    const [allBrands, setAllBrands] = useState([]);

    const [currentEdition, setCurrentEditions] = useState(null);
    const [allEditions, setAllEditions] = useState([]);

    const [breadcrumbsProducts, setBreadcrumbsProducts] = useState([]);
    const [breadcrumbsVersions, setBreadcrumbsVersions] = useState([]);

    const [currentProduct, setCurrentProduct] = useState(null);
    const [currentVersion, setCurrentVersion] = useState(null);


    useEffect(() => {
        if (breadcrumbsAPI) {

            setAllClients(breadcrumbsAPI.clients);
            setAllBrands(breadcrumbsAPI.brands);
            setAllEditions(breadcrumbsAPI.editions);
            setBreadcrumbsProducts(breadcrumbsAPI.products);
            setBreadcrumbsVersions(breadcrumbsAPI.versions);


            breadcrumbsAPI.clients.map((client => {
                if (client.current) {
                    setCurrentClient(client);
                }
            }));

            breadcrumbsAPI.brands.map((brand => {
                if (brand.current) {
                    setCurrentBrand(brand);
                }
            }));

            breadcrumbsAPI.editions.map((editions => {
                if (editions.current) {
                    setCurrentEditions(editions);
                }
            }));

            breadcrumbsAPI.products.map((product => {
                if (product.current) {
                    setCurrentProduct(product);
                }
            }));
            breadcrumbsAPI.versions.map((version => {
                if (version.current) {
                    setCurrentVersion(version);
                }
            }));


        }
    }, [breadcrumbsAPI]);


    const switchTeam = (teamUUID) => {
        // console.log("SWITCHING TEAM", teamUUID);

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/switch-team/' + teamUUID, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + session.accessToken, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {
                //        console.log("API SELECTED RESPONSE", data);
                //  clientLogo
                //
                if (data.message !== undefined) {
                    Sentry.captureException('auth/switch-team/' + teamUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }
                if (data.token !== undefined && data.token !== null) {
                    // window.localStorage.setItem('userTeam', data.token);
                    // window.localStorage.setItem('userTeamUUID', teamUUID);
                    setCookie('userTeam', data.token, { maxAge: 10 * 365 * 24 * 60 * 60 });
                    setCookie('userTeamUUID', teamUUID, { maxAge: 10 * 365 * 24 * 60 * 60 });
                    // console.log("SUCESS TOKEN", data);
                    // router.push('/');
                    router.reload(window.location.pathname);
                }
            });

    };

    const [showShareDialog, setShowShareDialog] = useState(false);
    const [showShareDialogTransition, setShowShareDialogTransition] = useState(false);

    const changeShareShow = (variable) => {

        if (variable === true) {
            setShowShareDialog(true);

            setTimeout(function() {
                setShowShareDialogTransition(variable);
            }, 2);


        } else {


            setShowShareDialogTransition(false);
            setTimeout(function() {
                setShowShareDialog(variable);
            }, 500);


        }

    };

    const [showLockDialog, setShowLockDialog] = useState(false);
    const [showLockDialogTransition, setShowLockDialogTransition] = useState(false);
    const changeLockShow = (variable) => {
        if (variable === true) {
            setShowLockDialog(true);
            setTimeout(function() {
                setShowLockDialogTransition(variable);
            }, 2);
        } else {
            setShowLockDialogTransition(false);
            setTimeout(function() {
                setShowLockDialog(variable);
            }, 500);
        }
    };

    // };

    const changeMenuTypeOpened = (type) => {


        if (type === 'menu' && !showUserMenu && showNotifications) {
            changeShowNotifications(false);
            changeShowUserMenu(true);
        }
        if (type === 'menu' && !showUserMenu && !showNotifications) {
            changeShowUserMenu(true);
        }

        if (type === 'menu' && showUserMenu) {
            changeShowUserMenu(false);
        }

        if (type === 'notification' && !showNotifications && showUserMenu) {
            changeShowUserMenu(false);
            changeShowNotifications(true);
        }
        if (type === 'notification' && !showNotifications && !showUserMenu) {
            changeShowNotifications(true);
        }

        if (type === 'notification' && showNotifications) {
            changeShowNotifications(false);
        }

    };

    const lockProductVersion = (masterProductUUID, versionProductUUID) => {
        //
        //versionIsLocked={versionIsLocked} setVersionIsLocked={setVersionIsLocked}
        //   console.log("LOCKING ", versionIsLocked, masterProductUUID, versionProductUUID);

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'products/' + masterProductUUID + '/versions/' + versionProductUUID, {
            method: 'PUT',
            body: JSON.stringify({
                'locked': versionIsLocked ? false : true,
            }),
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {
                // console.log("DATA API LOCK VERSION", data);
                if (data.message !== undefined) {
                    Sentry.captureException('products/' + masterProductUUID + '/versions/' + versionProductUUID + ': ' + data.message + ' : ' + getCookie('userMail'));
                }
                if (data.message === undefined) {
                    setVersionIsLocked(data.data.locked.locked);
                    changeLockShow(false);
                }


            });
    };



    const [allTeamNotificationsInvite, setAllTeamNotificationsInvite] = useState([]);
    const [enableNotifications, setEnableNotifications] = useState(false);
    const loadNotifications = () => {

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'team-invitations', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {

                if (data.message !== undefined) {
                    Sentry.captureException('team-invitations' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

              //  console.log('AL TEAM INVITATIOS', data);
                if (data.length > 0) {
                    setEnableNotifications(true);
                    setAllTeamNotificationsInvite(data);
                } else {
                    setEnableNotifications(false);
                    setAllTeamNotificationsInvite([]);
                }


            });

    };

    let handleClickDuplicateVersion = () => {
        console.log("CLICKA NA Delete");
    };

    const loadUserInfoAPI = (teamSelect) => {
        var tokenMain;

        if (typePlugin) {

                tokenMain = getCookie('pluginLoginSessionToken');


        } else {

            if(teamSelect){
                tokenMain = session.accessToken;
            } else {
                tokenMain = getCookie('userTeam');
            }
        }

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/info', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenMain, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {
            console.log('DATA API USER INFO', data);
                //  clientLogo
                if (data.message === 'Username could not be found.') {
                    if(typePlugin){
                        signOutPlugin();
                    } else {
                        signOutFunction();
                    }


                }

                if(data.status === 401){
                   // console.log("DATA USER INFO");
                    if(typePlugin){
                        signOutPlugin();
                    } else {
                        signOutFunction();
                    }
                } else {


                    //   console.log(session.token);
                    if (data.user.avatar && data.user.avatar !== undefined) {
                        setUserAvatar(data.user.avatar);
                    }

                    setUserEmail(data.user.email);
                    setUserName(data.user.full_name);
                    setUserUUID(data.user.uuid);
                    setUserTeams(data.teams);

                    console.log("THIS IS TEAMS ", data.teams);
                    if (!getCookie('userMail') && (getCookie('userMail') !== data.user.email)) {
                        setCookie('userMail', data.user.email, { maxAge: 10 * 365 * 24 * 60 * 60 });
                    }


                    Object.keys(data.teams).map(key => {
                        //  console.log("DATA TEAMS", key,  data.teams[key]);
                        if (getCookie('userTeamUUID') === data.teams[key]['uuid']) {
                            setTeamAvatar(data.teams[key]['logo']);

                                setTeamCreditsInner(data.teams[key]['credits']);

                            if(setTeamCredits){
                                setTeamCredits(data.teams[key]['credits']);
                            }

                        }

                    });

                }
                if (data.message !== undefined) {
                    Sentry.captureException('auth/info' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

            });
    };
    useEffect(() => {
        if (typePlugin) {
            loadUserInfoAPI(false);
        }
        if (userAvatar === 'default' && getCookie('userTeam') && !typeShareView) {
            loadUserInfoAPI(false);
            loadNotifications();
        }

        if (userAvatar === 'default' && !getCookie('userTeam') && !typeShareView) {
            loadUserInfoAPI(true);
        }


    }, []);


    let handleClickCreateNewTeam = () => {
      //  console.log('CLICKA NA Move');
    };
    let handleLockVersion = () => {

    };


    let signOutPlugin = () => {
   //     console.log('SIGNOUT PLUGIN');

        deleteCookie('pluginLoginSessionToken');
        deleteCookie('pluginLoginSessionTeamToken');
        deleteCookie('loginByPlugin');


        router.push('/plugin/adobe_login');
    };

    let signOutFunction = () => {
        deleteCookie('userTeam');
        deleteCookie('userTeamUUID');
        deleteCookie('usrA');

        signOut({ redirect: false }).then(() => {
            router.push('/'); // Redirect to the dashboard page after signing out
        });

        // signOut();
    };

    //


    const acceptInvite = (invitationUUID, inviteTeamUUID) => {

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'team-invitations/' + invitationUUID + '/accept', {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {
         //       console.log('Accept Invite', data);

                if (data.message === undefined) {

                    fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/switch-team/' + inviteTeamUUID, {
                        method: 'POST',
                        headers: {
                            'Content-type': 'application/json',
                            'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
                        },
                    })
                        .then(response => response.json())
                        .then(function(data) {
                            //        console.log("API SELECTED RESPONSE", data);
                            //  clientLogo
                            //
                            if (data.token !== undefined && data.token !== null) {
                                // window.localStorage.setItem('userTeam', data.token);
                                // window.localStorage.setItem('userTeamUUID', inviteTeamUUID);
                                setCookie('userTeam', data.token, { maxAge: 10 * 365 * 24 * 60 * 60 });
                                setCookie('userTeamUUID', inviteTeamUUID, { maxAge: 10 * 365 * 24 * 60 * 60 });
                                // console.log("SUCESS TOKEN", data);
                                // router.push('/');
                                router.reload(window.location.pathname);
                            }
                        });

                }
            });
    };

    const declineInvite = (invitationUUID) => {
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'team-invitations/' + invitationUUID + '/reject', {
            method: 'PUT',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + getCookie('userTeam'), // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {
            //    console.log('Rejecet Invite', data);
                loadNotifications();
                if (data.message === undefined) {

                    changeShowNotifications(false);

                }
            });
    };


    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    let handleClickSwitchTeams = () => {
         console.log('CLICKA NA Switch');
        // changeShowSwitchTeams(true);
    };


    return (
        <>
        <MainMenuHeaderNew style={typePlugin ? { position: 'relative', zIndex: '99999999999999999999' } : null}>

            <nav className="main-nav dark stick-fixed js-transparent small-height">
                <div className="full-wrapper relative clearfix">

                    <div className="nav-logo-wrap local-scroll">


                        <div style={{ float: 'left' }} onClick={() => window.open("/", "_self")}>

                                <div className="logo small-height">
                               <Link href={"/"}><img src={'/images/logoObalero.svg'} /></Link>
                                </div>

                        </div>



                        {typeEditor && !typePlugin ?
                            <div style={{ float: 'left' }} className={'breadcrumbs'}>

                                <ul>

                                    {currentClient ?
                                        <>
                                            <li onMouseEnter={() => changeShowDropdownFunction(true, 'client')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'client')}>
                                                <span onClick={() => window.open("/?clients="+currentClient.uuid, "_self")}> {currentClient.name} </span>
                                                {allClients.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showClient ? 'show' : ''}`}>

                                                        {allClients.map((value, index) => {

                                                            return <li className={` ${value.uuid === currentClient.uuid ? 'active' : ''}`} onClick={() => window.open("/?clients="+value.uuid, "_self")}>
                                                                {value.name}
                                                            </li>;


                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>

                                            <li className={'breadcrumbArrow'}>
                                                <FontAwesomeIcon icon={faCaretRight}/>
                                            </li>
                                        </>
                                        : null}

                                    {currentBrand ?
                                        <>
                                            <li onMouseEnter={() => changeShowDropdownFunction(true, 'brand')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'brand')}>
                                                <span onClick={() => window.open("/?brands="+currentBrand.uuid, "_self")}> {currentBrand.name} </span>

                                                {allBrands.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showBrand ? 'show' : ''}`}>

                                                        {allBrands.map((value, index) => {

                                                            return <li className={` ${value.uuid === currentBrand.uuid ? 'active' : ''}`} onClick={() => window.open("/?brands="+value.uuid, "_self")}>
                                                                {value.name}
                                                            </li>;


                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>

                                            <li className={'breadcrumbArrow'}>
                                                <FontAwesomeIcon icon={faCaretRight}/>
                                            </li>
                                        </>
                                        : null}

                                    {currentEdition ?
                                        <>
                                            <li
                                                onMouseEnter={() => changeShowDropdownFunction(true, 'edition')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'edition')}
                                            >
                                                <span onClick={() => window.open("/?edition="+currentEdition.uuid, "_self")}> {currentEdition.name} </span>

                                                {allEditions.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showEdition ? 'show' : ''}`}>
                                                        {allEditions.map((value, index) => {
                                                            return <li className={` ${value.uuid === currentEdition.uuid ? 'active' : ''}`}  onClick={() => window.open("/?editions="+value.uuid, "_self")}>
                                                                {value.name}
                                                            </li>;
                                                        })}
                                                    </ul>
                                                    : null}

                                            </li>
                                            <li className={'breadcrumbArrow'}>
                                                <FontAwesomeIcon icon={faCaretRight}/>
                                            </li>
                                        </>
                                        : null}

                                    {currentProduct ?
                                        <>
                                            <li onMouseEnter={() => changeShowDropdownFunction(true, 'packageType')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'packageType')}>

                                                <span> {currentProduct.name} </span>

                                                {breadcrumbsProducts.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showPackageType ? 'show' : ''}`}>

                                                        {breadcrumbsProducts.map((value, index) => {

                                                            return <li className={` ${value.uuid === currentProduct.uuid ? 'active' : ''}`} onClick={() => window.open("/products/"+value.uuid, "_self")}>
                                                                {value.name}
                                                            </li>;


                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>
                                            <li className={'breadcrumbArrow'}>
                                                <FontAwesomeIcon icon={faCaretRight}/>
                                            </li>
                                        </>
                                        : null}
                                    {currentVersion ?
                                        <>

                                            <li onMouseEnter={() => changeShowDropdownFunction(true, 'versions')}
                                                onMouseLeave={() => changeShowDropdownFunction(false, 'versions')}
                                            >
                                                     <span >  {currentVersion.name}  </span>
                                                {breadcrumbsVersions.length > 1 ?
                                                    <ul className={` mn-sub subVersions ${showVersions ? 'show' : ''}`}>

                                                        {breadcrumbsVersions.map((value, index) => {

                                                            return <li className={` ${value.uuid === currentVersion.uuid ? 'active' : ''}`} onClick={() => window.open("/editor/"+value.uuid, "_self")}>

                                                                {value.name}
                                                            </li>;


                                                        })}
                                                    </ul>
                                                    : null}
                                            </li>
                                        </>
                                        : null}
                                    {!typeShareView ?
                                        <li className={'breadcrumbsButtons'} style={{ marginLeft: '15px' }}
                                            onClick={() => handleLockVersion()}>
                                            {versionIsLocked ?
                                                <FontAwesomeIcon
                                                    data-tip="Clone version" data-place="bottom" data-delay-show="700"
                                                    className={'menuIcon brButton'}
                                                    icon={faLock}
                                                />
                                                :
                                                <FontAwesomeIcon
                                                    data-tip="Clone version" data-place="bottom" data-delay-show="700"
                                                    className={'menuIcon brButton'}
                                                    icon={faUnlock} />
                                            }

                                        </li>
                                        : null}
                                    {!typeShareView ?
                                        <li className={'breadcrumbsButtons'} onClick={()=> handleClickDuplicateVersion()}>
                                            <FontAwesomeIcon
                                                data-tip="Clone version" data-place="bottom" data-delay-show="700"
                                                className={'menuIcon brButton'}
                                                icon={faPlus} />
                                        </li>
                                        : null}


                                </ul>


                            </div>
                            : null}


                    </div>
                    <div className="mobile-nav small-height" role="button" aria-expanded="false"
                         style={{ height: '75px', lineHeight: '75px', width: '75px' }}>
                        <i className="fa fa-bars" aria-hidden="true"></i>
                        <span
                            className="sr-only">Menu</span></div>

                    <div className="inner-nav desktop-nav">
                        {typeEditor ?
                            <div className={'menuControlButtons'}>
                                {/*<GlobalButtonFinal className={"btn-circle backgroundTransparent menuButtons renderButton"} onClick={() => hitTriggerShowRender(true)} data-tip="Render packshot" data-place="bottom" data-delay-show="700">*/}
                                {/*    <FontAwesomeIcon className={"iconBtn"} icon={faCamera}/>*/}
                                {/*    <span>Render packshot</span>*/}
                                {/*</GlobalButtonFinal>*/}

                                {!typeShareView ?

                                    <GlobalButtonFinal className={'btn-circle backgroundTransparent menuButtons'}
                                                       onClick={() => changeShareShow(true)} data-tip="Share"
                                                       data-place="bottom" data-delay-show="700">
                                        <FontAwesomeIcon className={'iconBtn'} icon={faShare} />
                                        <span>Share</span>
                                    </GlobalButtonFinal>
                                    : null}

                            </div>

                            : null}
                        <ul className={`clearlist mainMenuUl ${allTeamNotificationsInvite.length > 0 ? 'withNotification' : ''}`}>


                            {enableNotifications
                                ?

                                <li onClick={() => changeMenuTypeOpened('notification')}
                                    className={`notificationBell buttonMenuMain ${showNotifications ? 'active' : ''} `}>
                                    <FontAwesomeIcon className={`notificationBellIcon `} icon={faBell} />
                                    <span className={'alertNumber'}>{allTeamNotificationsInvite.length}</span>

                                    <ul className={` mn-sub subVersions notificationMenu ${showNotifications ? 'show' : ''}`}>

                                        {allTeamNotificationsInvite.map((value, index) => {

                                            return <li className={'notification'}>

                                                <div className={'notificationIcon'}>
                                                    <FontAwesomeIcon icon={faUsers} />
                                                </div>
                                                <div className={'notificationDesc'}>
                                                    <h6>You are invited to the team {value.team.name}, do you want to
                                                        accept it ?</h6>


                                                    <GlobalButtonFinal
                                                        onClick={() => acceptInvite(value.uuid, value.team.uuid)}
                                                        style={{
                                                            width: '48%',
                                                            paddingLeft: '10px',
                                                            paddingRight: '10px',
                                                        }}
                                                        className={'backgroundBlue btn-circle'}>Accept</GlobalButtonFinal>
                                                    <GlobalButtonFinal onClick={() => declineInvite(value.uuid)}
                                                                       style={{
                                                                           width: '48%',
                                                                           marginLeft: '5px',
                                                                           paddingLeft: '10px',
                                                                           paddingRight: '10px',
                                                                       }}
                                                                       className={'borderRed btn-circle'}>Reject</GlobalButtonFinal>
                                                </div>
                                            </li>;


                                        })}



                                    </ul>
                                </li>
                                : null}
                            {!typeShareView ?


                                <li
                                    className={showUserMenu ?  'buttonMenuMain mainMenuButton active' : 'buttonMenuMain mainMenuButton'}
                                    // onClick={() => changeMenuTypeOpened("menu")}
                                    style={sideMenuPage !== 'team-select'
                                        ? { marginLeft: '15px', marginTop: '1px' } : { marginLeft: '15px' }}
                                    onClick={() =>  changeShowUserMenu(showUserMenu ? false : true)}
                                >

                                    <FontAwesomeIcon className={'iconBtn faMenuBars'} icon={faBars} />

                                    <span style={{ marginLeft: '8px' }}>Menu</span>

                                    <ul className={` mn-sub ${showUserMenu ? 'show' : ''} menuInside  ${sideMenuPage === 'team-select' ? 'teamSelect' : ''}`}>

                                        <span className={'dividerText'}>Me</span>

                                        <li className={'userInfo'} key={'userInfo'}>
                                            <div style={{
                                                textAlign: 'center',
                                                lineHeight: '6',
                                                width: '30%',
                                                display: 'inline-block',
                                                float: 'left',
                                            }}>
                                                <img src={userAvatar} />
                                            </div>
                                            <div style={{
                                                width: '60%',
                                                display: 'inline-block',
                                                float: 'left',
                                            }}>
                                                <h5 style={{marginBottom:"0",
                                                }}>{userName}</h5>
                                                <span>{userEmail}</span>


                                            </div>
                                            {sideMenuPage !== 'team-select' ?
                                                <li key={'myAccount'}>
                                                    <Link href="/profile"><FontAwesomeIcon style={{
                                                        height: '1.2em',
                                                        marginRight: '10px',
                                                        color: 'lightgray',
                                                        filter: 'none !important',
                                                    }} icon={faUser} /> My Account
                                                    </Link>
                                                </li>
                                                : null}


                                            <li key={'signout'}
                                                onClick={typePlugin ? () => signOutPlugin() : () => signOutFunction()}>
                                                <a>
                                                    <div href=""><FontAwesomeIcon style={{
                                                        height: '1.2em',
                                                        marginRight: '10px',
                                                        color: 'lightgray',
                                                        filter: 'none !important',
                                                    }} icon={faRightFromBracket} /> Sign Out
                                                    </div>
                                                </a></li>

                                        </li>

                                        {sideMenuPage !== 'team-select' && !typePlugin
                                            ?
                                            <>

                                                <li><span className={'dividerText team'}>Team</span></li>

                                                <li className={'teamInfo'}
                                                    key={'teamInfo'}>
                                                    <div style={{
                                                        textAlign: 'center',
                                                        lineHeight: '6',
                                                        width: '30%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                        <img src={teamAvatar ? teamAvatar : '/images/logo.svg'} />
                                                    </div>
                                                    <div style={{
                                                        width: '60%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                        {Object.keys(userTeams).map(key => {
                                                            if (getCookie('userTeamUUID') === userTeams[key]['uuid']) {
                                                                return <h5 style={{ marginBottom: '5px' }}
                                                                           key={userTeams[key]['uuid']}> {userTeams[key]['name']}</h5>;
                                                            }
                                                        })}


                                                        <span className={'plan'}>
                                                            <FormattedMessage {...messages.mainmenu.basicplan}></FormattedMessage>
                                                        </span>

                                                        <span className={'credits'}>
                                                                {teamCredits} CREDITS
                                                            </span>

                                                    </div>

                                                </li>


                                                <li key={'profile'}><Link href="/payments-credits">
                                                    <div href=""><FontAwesomeIcon style={{
                                                        height: '1.2em',
                                                        marginRight: '10px',
                                                        color: 'lightgray',
                                                        filter: 'none !important',
                                                    }} icon={faCoins} />Purchase credits
                                                    </div>
                                                </Link></li>
                                                <li key={'upgrade'}><Link href="/subscription">
                                                    <div href=""><FontAwesomeIcon style={{
                                                        height: '1.2em',
                                                        marginRight: '10px',
                                                        color: 'lightgray',
                                                        filter: 'none !important',
                                                    }} icon={faUp} />Upgrade
                                                    </div>
                                                </Link></li>
                                                <li
                                                    key={'switchteams'}

                                                    onClick={() =>handleClickSwitchTeams()}

                                                ><a className={` ${showSwitchTeams ? 'active' : ''}`}>
                                                    <FontAwesomeIcon style={{
                                                        height: '1.2em',
                                                        marginRight: '10px',
                                                        color: 'lightgray',
                                                        filter: 'none !important',
                                                    }} icon={faArrowRightArrowLeft}/>
                                                    Switch teams</a>

                                                </li>

                                            </>
                                            : null}

                                        <li><span className={'dividerText team'}>Help</span></li>

                                        {userDeveloper ?
                                            <li key={'developer'}><Link href="/ui-kit-3">
                                                <div href="">UIKit</div>
                                            </Link></li>
                                            : null}


                                        {/*<li key={'help'}><Link href="/help">*/}
                                        {/*    <div href=""><FontAwesomeIcon style={{*/}
                                        {/*        height: '1.2em',*/}
                                        {/*        marginRight: '10px',*/}
                                        {/*        color: 'lightgray',*/}
                                        {/*        filter: 'none !important',*/}
                                        {/*    }} icon={faCircleQuestion} />Help*/}
                                        {/*    </div>*/}
                                        {/*</Link></li>*/}

                                        <li key={'contact-support'}
                                                onClick={() => window.Intercom("show")}
                                        >
                                            <a >
                                            <div><FontAwesomeIcon style={{
                                                height: '1.2em',
                                                marginRight: '10px',
                                                color: 'lightgray',
                                                filter: 'none !important',
                                            }} icon={faComments} />Contact support
                                            </div></a>
                                        </li>


                                        {sideMenuPage !== 'team-select' && typePlugin
                                            ?
                                            <>
                                                <li key={'userInfo'} className={'userInfo'}>
                                                    <div style={{
                                                        textAlign: 'center',
                                                        lineHeight: '6',
                                                        width: '30%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                        <img src={userAvatar} style={{ height: '30px' }} />
                                                    </div>
                                                    <div style={{
                                                        width: '60%',
                                                        display: 'inline-block',
                                                        float: 'left',
                                                    }}>
                                                        <h5>{userName}</h5>
                                                        <span><em>{userEmail}</em></span>
                                                        <p>
                                                            {Object.keys(userTeams).map(key => {
                                                                return (<>
                                                                    {getCookie('userTeamUUID') === userTeams[key]['uuid'] ?
                                                                        <span> {userTeams[key]['name']}</span>
                                                                        :
                                                                        null}
                                                                </>);

                                                            })}
                                                        </p>


                                                        <p className={'plan'}>
                                                            <FormattedMessage {...messages.mainmenu.basicplan}></FormattedMessage>
                                                        </p>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <hr />
                                                    </div>

                                                </li>
                                                <li
                                                    key={'teams'}
                                                    onMouseEnter={() => changeShowSwitchTeams(true)}
                                                    onMouseLeave={() => changeShowSwitchTeams(false)}

                                                ><a className={` ${showSwitchTeams ? 'active' : ''}`}> {'<  '}Switch
                                                    teams</a>
                                                    <ul className={` mn-sub ${showSwitchTeams ? 'show' : ''}`} style={{
                                                        left: '-209%',
                                                        top: '-5px',
                                                        borderRadius: '22px',
                                                        paddingTop: '5px',
                                                    }}>


                                                        {Object.keys(userTeams).map(key => {
                                                            if (userTeams[key]['logo'] !== null) {
                                                                return (<li
                                                                    key={userTeams[key]['uuid']}
                                                                    className={getCookie('userTeamUUID') === userTeams[key]['uuid'] ? 'active teamSwitcher' : 'teamSwitcher'}
                                                                    onClick={() => switchTeam(userTeams[key]['uuid'])}>
                                                                    <a>
                                                                        <img src={userTeams[key]['logo']} />
                                                                        <span className={'spanAdjust'}>
                                                        {userTeams[key]['name']}
                                                </span>
                                                                    </a>
                                                                </li>);
                                                            } else {
                                                                return (<li
                                                                    key={userTeams[key]['uuid']}
                                                                    className={getCookie('userTeamUUID') === userTeams[key]['uuid'] ? 'active teamSwitcher' : 'teamSwitcher'}
                                                                    onClick={() => switchTeam(userTeams[key]['uuid'])}>
                                                                    <a>
                                                                        <FontAwesomeIcon icon={faUsers} />
                                                                        <span className={'spanAdjust'}>
                                                        {userTeams[key]['name']}
                                                </span>
                                                                    </a>
                                                                </li>);
                                                            }


                                                        })}


                                                        <li key={'hr'}>
                                                            <div style={{ width: '100%' }}>
                                                                <hr />
                                                            </div>
                                                        </li>

                                                        <li key={'createTeam'}
                                                            onClick={() => handleClickCreateNewTeam()}><a>+ Create
                                                            team</a></li>

                                                    </ul>

                                                </li>
                                            </>
                                            : null}


                                    </ul>

                                </li>

                                : null}


                        </ul>
                    </div>

                </div>
            </nav>

            <DialogLockVersion
                productLocked={versionIsLocked}
                masterProductID={masterProductUUID}
                productID={versionProductUUID}
                setVersionIsLocked={setVersionIsLocked}
                toggleLockVersion={(toggleLockVersion: () => void) => {
                    handleLockVersion = toggleLockVersion;
                }}
            />


            <ModalsWrapper
                className={`  ${showLockDialogTransition ? 'showTransition' : 'hideTransition'} ${!showLockDialog ? 'hiddeComplete' : ''}`}>


                <ModalWindow id={'lockProductDialog'} style={{ minHeight: 'unset', height: '170px' }}
                             className={'center'}>
                    <div className={'modal-header'}>
                        <h4>Version lock</h4>
                    </div>


                    <div className={'modal-content shareProduct'} style={{ display: 'block' }}>
                        {versionIsLocked ?
                            <span>Do you want to unlock the current version?</span>
                            :
                            <span>Do you want to lock the current version?</span>}


                        <div className={'modal-buttons'}>

                            <GlobalButtonFinal style={{ width: '48%', float: 'left' }}
                                               className={'backgroundBlue btn-circle '} data-uuid={'productID'}
                                               onClick={() => lockProductVersion(masterProductUUID, versionProductUUID)}>

                                {versionIsLocked ?
                                    <div><FontAwesomeIcon icon={faUnlock} /> Unlock version</div>
                                    :
                                    <div><FontAwesomeIcon icon={faLock} /> Lock version</div>}


                            </GlobalButtonFinal>
                            <GlobalButtonFinal style={{ width: '48%', marginLeft: '15px', float: 'left' }}
                                               className={'borderBlue btn-circle'}
                                               onClick={() => changeLockShow(false)}>
                                Cancel
                            </GlobalButtonFinal>
                        </div>

                    </div>
                </ModalWindow>


            </ModalsWrapper>

            <DialogDuplicateVersion
                masterProductUUID={masterProductUUID}
                toggleDuplicateVersion={(toggleDuplicateVersion: () => void) => {
                    handleClickDuplicateVersion = toggleDuplicateVersion;
                } }
                versionProductUUID={versionProductUUID}
            />






            <DialogCreateNewTeam
                switchTeam={switchTeam}
                toggleCreateNewTeam={(toggleCreateNewTeam: () => void) => {
                    handleClickCreateNewTeam = toggleCreateNewTeam;
                }}
            />


            {typeEditor && !typeShareView ?
                <ShareDialog
                    slugState={slugState}
                    typeEditor={typeEditor}
                    showShareDialog={showShareDialog}
                    showShareDialogTransition={showShareDialogTransition}
                    changeShareShow={changeShareShow} />
                : null}



        </MainMenuHeaderNew>

            <DialogSwitchTeams
                loadUserInfoAPI={loadUserInfoAPI}
                toggleSwitchTeams={ (toggleSwitchTeams: () => void) => {
                    handleClickSwitchTeams = toggleSwitchTeams;
                } }
                userTeams={userTeams}

            />
        </>
    );
}