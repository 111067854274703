import MainMenu from './menu/MainMenu';

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Link from "next/link";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowRightArrowLeft,
    faCoins,
    faAnglesRight,faLock,
    faUser, faUsers,faBuilding,
    faSignalBars,
    faCreditCard
} from "@fortawesome/pro-regular-svg-icons";
import {getCookie, setCookie} from "cookies-next";

import * as Sentry from "@sentry/nextjs";
import DialogSwitchTeams from './dialogs/DialogSwitchTeams';




export const SideMenu  = styled.div`
  width: 15%;
  height: 100%;
  position: fixed;
  background: var(--black-5, #F2F2F2);
  padding:22px;
    padding-top:77px;
  .mn-sub {
min-width:250px;
    width: 100%;
height:100%;
    //overflow-y:scroll;
 
    border-bottom-left-radius: 22px;
    border-bottom-right-radius:0;
    right: -24px !important;

    transition: all 0.3s ease;
      border-radius: 0 !important;
    top: 0;
padding:0;

      overflow-y: scroll;
      padding-bottom: 50px;
      min-width: 190px;
      
   
      
      .switchTeamsBtn{
          &:hover{
              cursor:pointer;
          }
      }

    .dividerText{
      font-size: 18px !important;
      position: relative;
      color: rgba(255, 255, 255, 0.6);

      text-align: center;


      font-style: normal;
      font-weight: 300;
      line-height: normal;
      width: 90%;
      position: relative;
      margin:auto auto;
      display: block;

      &:before {
        content: "";
        display: block;

        height: 1px;
        background: rgba(255, 255, 255, 0.6);
        left: 0;
        top: 50%;
        width: 70px;
        position: absolute;
      }


      &:after {
        content: "";
        display: block;
        width: 70px;
        height: 1px;
        background: rgba(255, 255, 255, 0.6);
        right: 0;
        top: 50%;
        position: absolute;
      }

      &.team{
        &:before {
          width: 60px;
        }


        &:after {
          width: 60px;
        }
      }

    }

    .teamSwitcher {
      img {
        margin-right: 10px;
        //max-width: 15px;
        //
        //width: 15px;
        //height: 15px;
      }

      svg {
        //width: 15px;
        margin-right: 10px;
      }

      &.active {
        background: rgba(255, 255, 255, .09);
        color: #f5f5f5 !important;
      }
      span{
        color: var(--black-40, #999);
        font-family: "Nunito Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    &.show {
      //   display:block;
      visibility: visible;
      opacity: 1 !important;
      //transform: translateY(0);
    }

    .userInfo {
      text-align: left !important;


      h5 {



        text-align: left !important;
        margin-top: 4px;

        color: var(--black-70, #4C4C4C);
        font-family: "Nunito Sans";
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400  !important;
        line-height: normal;
        margin-bottom:0 !important;

      }

      span {

        color: var(--black-40, #999) !important;
        font-family: "Nunito Sans";
        font-size: 16px  !important;
        font-style: normal !important;
        font-weight: 300  !important;
        line-height: normal;
      }

      .plan {
        color: var(--black-100, #000) !important;
        font-family: "Nunito Sans" !important;
        font-size: 10px !important;
        font-style: normal;
        padding: 1px 5px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
        border-radius: 3px;
        background: var(--black-40, #999) !important;
      }
      .credits{
        color: var(--black-15, #D9D9D9) !important;
        font-family: "Nunito Sans";
        font-size: 10px !important;
        font-style: normal !important;

        font-weight: 700;
        line-height: normal;
        margin-left:10px;
      }

      hr {
        width: 85%;
        background: lightgray;
        height: 2px;
        opacity: 1;
        margin: auto;
      }

      img{
        width:48px;
        border-radius:50%;
        height:48px;
      }
    }
    
    .teamInfo{
      text-align: left !important;
         margin-top:30px !important;

      h5 {



        text-align: left !important;
        margin-top: 15px;

        color: var(--black-70, #4C4C4C);
        font-family: "Nunito Sans";
        font-size: 16px  !important;
        font-style: normal  !important;
        font-weight: 400  !important;
        line-height: normal;
        margin-bottom:0 !important;

      }

      span {

        color: var(--black-40, #999) !important;
        font-family: "Nunito Sans";
        font-size: 14px  !important;
        font-style: normal !important;
        font-weight: 300;
        line-height: normal;
      }

      .plan {
        color: var(--black-100, #000) !important;
        font-family: "Nunito Sans" !important;
        font-size: 10px !important;
        font-style: normal;
        padding: 1px 5px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: normal;
        border-radius: 3px;
        background: var(--black-40, #999) !important;
      }
      .credits{
        color: var(--black-15, #D9D9D9) !important;
        font-family: "Nunito Sans";
        font-size: 10px !important;
        font-style: normal !important;

        font-weight: 700;
        line-height: normal;
        margin-left:10px;
      }

      hr {
        width: 85%;
        background: #D9D9D9;
        height: 2px;
        opacity: 1;
        margin: auto;
      }

      img{
        width:48px;
        height:48px;
        border-radius:50%;
      }
    }

  }

  .mn-sub li {

    width: 100%;
    margin: 0 !important;

    float: left;
    position: relative;
    //line-height: 3.9;
    display: block;
    width: 100%;
    margin-top:20px !important;
    transition:all 0.3s ease;
    a,span{
      color: var(--black-60, #666);
      /* Top Menu/Item */
      font-family: "Nunito Sans";
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400  !important;
      transition:all 0.3s ease;
      line-height: normal;      
    }
    svg{
      width:16px;
      transition:all 0.3s ease;
      height:auto;
    }
    &:hover,
    &.active{
      span,
      svg{
        color: var(--black-80, #333) !important;   
      }
     
    }
    
  }
    

    hr {
      width: 85%;
      background: #D9D9D9;
      height: 1px;
      opacity: 1;
 
      border: none !important;
      margin-top: 61px;
      display: block;
      position: relative;
      top: 10px;
    }


    
    &.inactiveMobile{
        img,
        p,
        span,
        h5,
        hr{
            display: none;
        }
    }
    @media (max-width:1400px){
        width:20%;
    }
    
    @media (max-width:1100px){
        width:25%;
    }

    @media (max-width:900px){
        width:30%;
    }

    @media (max-width:700px){
        width:35%;
    }

    @media (max-width:500px){
        width:60px;
        overflow:hidden;
    }



`;

export const MainLayout  = styled.div`
padding-top:55px;
  width: 100%;
  height: 100%;
  position: absolute;

  &.editor{
    width:100%;
      top: 54px;
    overflow:hidden;
    height: calc(100% - 54px);
  }
  &.isLogin{
    width:100%;
  }

    
  &.isSideMenu{
    width:85%;
    background: white;
    margin-left:15%;
  }

    @media (max-width:1400px){
        &.isSideMenu{
            width:80%;
            margin-left:20%;
        }
    }
    
    @media (max-width:1100px){
        &.isSideMenu{
            width:75%;
            margin-left:25%;
        }
    }
    @media (max-width:900px){
        &.isSideMenu{
            width:70%;
            margin-left:30%;
        }
    }


    @media (max-width:700px){
        &.isSideMenu{
            width:65%;
            margin-left:35%;
        }
    }


    @media (max-width:500px){
        &.isSideMenu{

            width: calc(100% - 60px);
            margin-left:60px;
        }
        
        h3{
            font-size: 23px !important;
        }
    }
    
    //&.editor{
  //  height: calc(100% - 71px);
  //}

`;

function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}


export default function Layout ({children, showMainMenu, typeShareView,productNameMaster,
                                    productVersionNameMaster,breadcrumbsAPI,
                                   showSideMenu, typeEditor, typeEditorPlugin, sideMenuPage,isLogin,
                                     masterProductUUID,   versionProductUUID,
                                    versionIsLocked, setVersionIsLocked, teamCredits, setTeamCredits, handleClick,hitTriggerShowRender}) {

    const size = useWindowSize();

    const [showMobileMenu, setShowMobileMenu] = useState(false);



    const [userAvatar, setUserAvatar] = useState('default');
    const [userEmail, setUserEmail] = useState('default email');
    const [userName, setUserName] = useState('default');
    const [userUUID, setUserUUID] = useState('default');
    const [userTeams, setUserTeams] = useState([]);
    const [teamAvatar, setTeamAvatar] = useState('default');
    const loadUserInfoAPI = () => {
        var tokenMain = getCookie('userTeam');

        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH + 'auth/info', {
            method: 'GET',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenMain, // notice the Bearer before your token
            },
        })
            .then(response => response.json())
            .then(function(data) {

                if(data.status === 401){

                } else {


                    if (data.user.avatar && data.user.avatar !== undefined) {
                        setUserAvatar(data.user.avatar);
                    }

                    setUserEmail(data.user.email);
                    setUserName(data.user.full_name);
                    setUserUUID(data.user.uuid);
                    setUserTeams(data.teams);

                    if (!getCookie('userMail') && (getCookie('userMail') !== data.user.email)) {
                        setCookie('userMail', data.user.email, { maxAge: 10 * 365 * 24 * 60 * 60 });
                    }


                    Object.keys(data.teams).map(key => {
                        if (getCookie('userTeamUUID') === data.teams[key]['uuid']) {
                            setTeamAvatar(data.teams[key]['avatar']);
                            if(setTeamCredits){
                                setTeamCredits(data.teams[key]['subscription']['recurring_credits']);
                            }

                        }

                    });

                }
                if (data.message !== undefined) {
                    Sentry.captureException('auth/info' + ': ' + data.message + ' : ' + getCookie('userMail'));
                }

            });
    };


    useEffect(() => {

        if (userAvatar === 'default' && getCookie('userTeam')) {
            loadUserInfoAPI();

        }

    }, []);

    let handleClickSwitchTeams = () => {

    };




    const [showUserMenu, changeShowUserMenu] = useState(false);
    const closeModalOutsideClickMenu = (e) => {
        if( e.target.getAttribute("data-closemodalmenu") === "true"){
            changeShowUserMenu(false);
        }
    }



    return (
        <>


            {
                typeEditorPlugin
                    ?
                    <MainLayout className={"editor"}
                                data-closemodalmenu={true}
                                onClick={(e)=> closeModalOutsideClickMenu(e)}>
                        {children}
                    </MainLayout>
                :
                    <>


                        <div >
                            {
                                showMainMenu
                                    ?
                                    <MainMenu
                                        showUserMenu={showUserMenu}
                                        changeShowUserMenu={changeShowUserMenu}
                                        breadcrumbsAPI={breadcrumbsAPI}
                                        versionIsLocked={versionIsLocked} setVersionIsLocked={setVersionIsLocked}
                                        teamCredits={teamCredits}
                                        setTeamCredits={setTeamCredits}
                                        sideMenuPage={sideMenuPage}
                                        typeShareView={typeShareView}
                                        productNameMaster={productNameMaster}
                                        productVersionNameMaster={productVersionNameMaster}
                                        handleClick={handleClick}
                                        hitTriggerShowRender={hitTriggerShowRender}
                                        typeEditor={typeEditor} masterProductUUID={masterProductUUID}  versionProductUUID={versionProductUUID}/>
                                    :
                                    null
                            }


                            {showSideMenu ?
                                <>
                                    <SideMenu className={` ${ size.width < 500 ? (  showMobileMenu ? 'activeMobile' : 'inactiveMobile' ) : ""}`} >
                                        <ul className={` mn-sub show`}>
                                            <li className={'userInfo'} key={'userInfo'}>
                                                <div style={{
                                                    textAlign: 'left',
                                                    lineHeight: '3',
                                                    width: '60px',
                                                    display: 'inline-block',
                                                    float: 'left',
                                                }}>
                                                    <img src={userAvatar} />
                                                </div>
                                                <div style={{
                                                    width: 'unset',
                                                    display: 'inline-block',
                                                    float: 'left',
                                                }}>
                                                    <h5>{userName}</h5>
                                                    {/*<span>{userEmail}</span>*/}


                                                </div>
                                                {/*<a href="">English</a>*/}
                                            </li>

                                            <li key={'profile'}
                                                className={`${sideMenuPage === 'profile' ? 'active' : ''} `}>
                                                <Link href={'/profile'}><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }} icon={faUser} /><span>Profile</span>
                                                </Link>
                                            </li>
                                            <li key={'security'}
                                                className={`${sideMenuPage === 'security' ? 'active' : ''} `}>
                                                <Link href={'/security'}><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }} icon={faLock} /><span>Security</span>
                                                </Link>
                                            </li>

                                            <li className={'teamInfo'} key={'teamInfo'}>
                                                <div style={{
                                                    textAlign: 'left',
                                                    lineHeight: '3',
                                                    width: '60px',
                                                    display: 'inline-block',
                                                    float: 'left',
                                                }}>
                                                    <img src={teamAvatar ? teamAvatar : '/images/logo.svg'} />
                                                </div>
                                                <div style={{
                                                    width: 'unset',
                                                    display: 'inline-block',
                                                    float: 'left',
                                                }}>

                                                    {Object.keys(userTeams).map(key => {
                                                        if (getCookie('userTeamUUID') === userTeams[key]['uuid']) {
                                                            return <h5 style={{ marginBottom: '5px' }}
                                                                       key={userTeams[key]['uuid']}> {userTeams[key]['name']}</h5>;
                                                        }
                                                    })}


                                                </div>

                                            </li>

                                            <li key={'team'} className={`${sideMenuPage === 'team' ? 'active' : ''} `}>
                                                <Link href={'/team'}><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }} icon={faBuilding} /><span>Team</span>
                                                </Link>
                                            </li>
                                            <li key={'members'}
                                                className={`${sideMenuPage === 'members' ? 'active' : ''} `}>
                                                <Link href={'/members'}><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }} icon={faUsers} /><span>Members</span>
                                                </Link>
                                            </li>

                                            <li key={'billing'}
                                                className={`${sideMenuPage === 'billing' ? 'active' : ''} `}>
                                                <Link href="billing"><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }} icon={faCreditCard} /><span>Billing</span>
                                                </Link>
                                            </li>

                                            <li key={'payments-credits'}
                                                className={`${sideMenuPage === 'payments-credits' ? 'active' : ''} `}>
                                                <Link href={'/payments-credits'}><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }} icon={faCoins} /><span>Payments & Credits</span>
                                                </Link>
                                            </li>


                                            {/*<li><a >  <FontAwesomeIcon style={{height: "1.1em",  marginRight: "5px", color: "lightgray", filter: "none !important"}}  icon={faGear}/>Preferences</a></li>*/}
                                            <li key={'subscription'}
                                                className={`${sideMenuPage === 'subscription' ? 'active' : ''} `}>
                                                <Link href="/subscription"><FontAwesomeIcon style={{
                                                    height: '1.1em',
                                                    marginRight: '10px',
                                                    color: 'lightgray',
                                                    filter: 'none !important',
                                                }} icon={faSignalBars} /><span>Subscription</span>
                                                </Link>
                                            </li>


                                            <hr />
                                            <li
                                                key={'switchteams'}
                                                className={'switchTeamsBtn'}
                                                onClick={() => handleClickSwitchTeams()}
                                                // onClick={() => changeShowSwitchTeams(true)}

                                            ><span
                                                //     className={` ${showSwitchTeams ? 'active' : ''}`}
                                            >
                                                        <FontAwesomeIcon style={{
                                                            height: '1.1em',
                                                            marginRight: '10px',
                                                            color: 'lightgray',
                                                            filter: 'none !important',
                                                        }} icon={faArrowRightArrowLeft} />
                                                        Switch teams</span>

                                            </li>


                                        </ul>
                                    </SideMenu>
                                    <DialogSwitchTeams
                                        loadUserInfoAPI={loadUserInfoAPI}
                                        toggleSwitchTeams={(toggleSwitchTeams: () => void) => {
                                            handleClickSwitchTeams = toggleSwitchTeams;
                                        }}
                                        userTeams={userTeams}

                                    />

                                    <button className={'showSideMenuMobile'}
                                            onClick={showMobileMenu ? () => setShowMobileMenu(false) : () => setShowMobileMenu(true)}>
                                    <FontAwesomeIcon icon={faAnglesRight} />
                                    </button>
                                    <MainLayout
                                        data-closemodalmenu={true}
                                        onClick={(e)=> closeModalOutsideClickMenu(e)}
                                        className={`   ${showSideMenu ? 'isSideMenu' : ''} ${isLogin ? 'isLogin' : ''} ${typeEditor ? 'editor' : ''}`}>
                                        {children}
                                    </MainLayout>
                                </>
                                :
                                <MainLayout
                                    data-closemodalmenu={true}
                                    onClick={(e)=> closeModalOutsideClickMenu(e)}
                                    className={`  ${isLogin ? "isLogin" : ""} ${typeEditor ? "editor" : ""}`}>
                                    {children}
                                </MainLayout>}

                        </div>





                    </>

            }

        </>
    )
}